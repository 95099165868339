import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styles from './styles.module.css';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';

const Accessibility = (props: any) => {
  return (
    <div>
      <Container className={styles.advanceBodyContainer}>
        <Row
          style={{
            marginTop: 60,
            direction: 'rtl',
            textAlign: 'right',
            fontWeight: 'bold',
            fontSize: 26,
            marginRight: 55,
            marginBottom: 20,
          }}
        >
          {'הצהרת נגישות'}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 30,
          }}
        >
          {`"אולטרה" ו/או "הבנקאים" הם סימני מסחר של חברת אף.טי.פי.אף. בע״מ (החברה). החברה מייחסת חשיבות רבה להתאמת שירותיה לאנשים עם מוגבוליות שונות. החברה שואפת לאפשר לכל בני האדם הזדמנות שווה ונגישות לשירות ומידע ובכלל זה בתחום האשראי והעסקים.`}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {
            'לחברה אין סניפים פיזיים השירות מתבצע באמצעות אתר האינטרנט של החברה הטלפון ושאר אמצעים דיגיטליים. בהתאם לאמור החברה משקיעה משאבים בהנגשת אתר האינטרנט ועמודיו במטרה לתת שירות שיוויוני לכלל גולשי האתר ולאנשים עם מגבלויות.'
          }
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {'הנגשת האתר והתכנים מתאפשרים באמצעות תפריט נגישות בחלקו הימני הזמין לכל דפי האתר.' +
            'לחיצה על התפריט מאפשרת פתיחת כפתורי הנגשה. יש לבחור מתוך התפריט את האפשרות המבוקשת.'}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {`במסגרת הנגשת האתר שמנו דגש בין היתר על יישום הסעיפים הבאים:
1.אפשרות לשינוי והאמת גודל הטקסט.
2. אפשרות לשינוי הגוון לאפור.
3. אפשרות לשינוי ניגודיות.
4. אפשרות לשינוי לרקע בהיר
5. אפשרות להדגשת קישורים
6. אפשרות להדגשת הפונט
`}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {'רכיבי ההנגשה פועלים בצורה מיטבית מתצוגה שולחנית בדפדפן כרום.'}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {
            'אנחנו עובדים תמיד במטרה לשפר את הנגישות באתר בפרט ובחברה בכלל לכלל האוכלוסייה לרבות אנשים עם מוגבולויות. לאור הדינמיות שבאתר ועבודת החברה ייתכן שחלקים מסויים לא יונגשו במלואם. אם חוויתם בעיה כלשהי או תקלה נשמח שתצרו קשר עם החברה ונוכל לתקן זאת בהקדם האפשרי. בנוסף נשמח לקבל כל פנייה שתאפשר לנו לשרת את כלל האוכלוסייה טוב יותר ולהנגיש את האתר טוב יותר.'
          }
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {`החברה רואה חשיבות רבה בהענקת שירות טוב, מקצועי, הוגן, שוויוני ונגיש לכולם, אולם תשומת לבכם אפשר שהאתר יציג תוכן גם מאתרים אחרים שמופעלים ו/או מוחזקים על ידי צדדים שלישיים, וייתכן שתוכן כאמור אינו מונגש. נבהיר, כי החברה אינה אחראית לתוכן האמור או להנגשתו מאחר ואינו נמצא בשליטתה, אך נשמח לקבל הערותיכם ולנסות לסייע לכם לפנות לאותם צדדים שלישיים, בכדי להסב תשומת ליבם לנושא (מבלי שנוכל להתחייב שאותם צדדים שלישיים יבצעו את ההתאמות הנדרשות, ככל שנדרשות, על פי דין).
`}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {`לבקשות והצעות בנושא נגישות אף.טי.פי.אף. בע”מ,
או אם במהלך הגלישה באתר נתקלת בקושי לגלוש באתר או אם יש לכם הערה בנוגע לרמת ו/או אופן הנגשתו, ניתן ליצור קשר עם רכז/ת הנגישות של החברה:
שם מלא: יונתן ברנד
מיקום: קרית ספר 19 תל אביב.
טלפון: 073-7423970 .
דוא”ל: yonatanb@habankaim.com .`}
        </Row>
        <Row
          style={{
            direction: 'rtl',
            textAlign: 'right',
            fontSize: 14,
            marginRight: 55,
            marginBottom: 40,
          }}
        >
          {`תאריך עדכון ההצהרה: חודש אוקטובר 2020`}
        </Row>
      </Container>
    </div>
  );
};

export default Accessibility;
